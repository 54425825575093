import {inject, LogManager} from "aurelia-framework";
import {Client} from "../../api/client";
import {UserClient} from "../../api/user-client";

const logger = LogManager.getLogger('ChoiceLoader');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(Client, UserClient)
export class ChoiceLoader {

    static Sets = new Map;

    constructor(client, userClient) {
        this.client = client;
        this.userClient = userClient;
    }

    getChoices(config, requestConditions = null, initialValues = null, extraQueryParams = "") {

        if (config.modelId || config.modelPropertyId) {

            let conditions = '';

            if (requestConditions) {
                conditions = 'conditions=' + JSON.stringify(requestConditions);
            }

            if (initialValues) {
                conditions += (conditions.length > 0 ? '&' : '') + 'initialValues=' + JSON.stringify(initialValues);
            }

            if (extraQueryParams) {
                conditions += (conditions.length > 0 ? '&' : '') + extraQueryParams;
            }

            if (config.customLabel) {
                conditions += `&fieldName=${config.customLabel}`;
            }

            const propertyUrl = config.modelPropertyId ? config.modelPropertyId : config.modelId;

            return this.client.get('model/choice/' + propertyUrl + '?' + conditions, 60);

        } else if (config.set) {

            // avoid requesting choice sets again and again

            if (!ChoiceLoader.Sets.has(config.set)) {

                const promise = this.client.get('form/choice/' + config.set).then(choices => {
                    ChoiceLoader.Sets.set(config.set, choices);
                    return Promise.resolve(choices);
                });

                ChoiceLoader.Sets.set(config.set, promise);

                return promise;
            }

            const choices = ChoiceLoader.Sets.get(config.set);

            return choices instanceof Promise ? choices : Promise.resolve(choices);

        } else if (config.choices) {
            return Promise.resolve(config.choices);
        } else {
            return Promise.resolve([]);
        }
    }

}
